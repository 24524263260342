<script>
import rolesService from '@/services/roles.service'
import Role from '@/data/models/Role'
import EditLayout from '@/layout/EditLayout'
import indexing from '@/data/enums/seoIndexing.enum'
import PermissionsCheckList from '@/components/PermissionsCheckList'

export default {
  name: 'SingleRole',
  components: {
    EditLayout,
    PermissionsCheckList,
  },
	data() {
		return {
      item: new Role,
      indexing: indexing,
      currentRolePermissions: [],
		}
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    isNew() {
      return this.id === 'new'
    },
  },
	mounted() {
    if(!this.isNew && this.id) {
      this._get()
      this._getRolePermissions()
    }
	},
	methods: {
    _get(type = null) {
      rolesService.getById({ id: this.id })
      .then(res => {
        this.item = res.data
      })
    },
    _getRolePermissions() {
      rolesService.getPermissionByRole({ id: this.id })
        .then(res => {
          this.currentRolePermissions = res.data
        })
    },
    create() {
      rolesService.create({ item: this.item })
        .then(res => {
          this.$notify({
            message: this.$tc('m.itemSuccessfullyAdded', { item: this.$tc('role') }),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
          this._saveRolePermissions(res.data.id)
          this.$store.dispatch('roles/getAllRoles')
          this.$router.push({ name: 'roles' })
        })
    },
    edit() {
      rolesService.edit({ item: this.item })
        .then(res => {
          this.$store.dispatch('roles/getAllRoles')
          this.$notify({
            message: this.$tc('m.itemSuccessfullySaved', { item: this.$tc('role') }),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
          this._saveRolePermissions(res.data.id)
        })
    },
    _saveRolePermissions(id) {
      rolesService.updateRolePermissions({ role: { id: id }, permissions: this.currentRolePermissions })
      .then(res => {
          this.$notify({
            message: this.$t('m.itemSuccessfullySaved', { item: this.$tc('rolePermission', 2) }),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
        })
    },
    updatePermissions(permissions) {
      this.currentRolePermissions = permissions
    },
	}
}
</script>

<template>
  <EditLayout 
    :item="item"
    :submit-button-text="isNew ? $t('create') : $t('save')"
    :supports-editor="false"
    @submit="isNew ? create() : edit()"
  >
    <PermissionsCheckList
      slot="bottom"
      :active-permissions="currentRolePermissions"
      @updatePermissions="updatePermissions"
    />
  </EditLayout>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

</style>
